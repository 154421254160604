import { createStore } from 'vuex'
import users from './users'
import { getFirestore , enableIndexedDbPersistence, onSnapshot } from "firebase/firestore"
import { collection, query, getDocs, where, addDoc, doc, getDoc, deleteDoc, updateDoc} from "firebase/firestore";
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    activity: null,
    activities: null,
    activity_id: null,
  },
  getters: {
      get_activities(state){
        return state.activities
      },
      get_activity(state){
        if ( state.activities === null ){
          return null
        }
        return state.activities.find(a=>a.id===state.activity_id)
      },
      get_activity_by_id: (state) => (activity_id) => {
        return state.activities.find(a=>a.id===activity_id)
      }
  },
  mutations: {
    set_activity(state, activity){
        state.activity = activity
    },
    set_activity_id(state, activity_id){
        state.activity_id = activity_id
    },
    set_activities(state, activities){
      state.activities = activities
    },
    set_entries(state, entries){
      state.activity.entries = entries
    },
    add_entries(state, entries){
      if ( !state.activity.entries ){
        state.activity.entries = []
      }
      entries.forEach(entry=>{
        let existing = state.activity.entries.find(e=>e.id===entry.id)
        if ( !existing ){
          state.activity.entries.push(entry)
        }
      })
    },
    save_last_sync(state, {activity_id, last_sync}){
      let activity = state.activities.find(a=>a.id===activity_id)
      activity.last_sync = last_sync
    },
    remove_entry(state, entry_id){
      state.activity.entries = state.activity.entries.filter(e=>e.id!==entry_id)
    },
    update_entry(state, entry){
      let existing = state.activity.entries.find(e=>e.id===entry.id)
      if ( existing ){
        existing = entry
      }
    },
    add_activity(state, {id, activity}){
      if ( state.activities === null ){
        state.activities = []
      }
      let existing_activity = state.activities.find(a => a.id === id)
      if (existing_activity) {
          existing_activity = { ...existing_activity, ...activity}
      } else {
        state.activities.push({id, ...activity})
      }
    },
    logout(state){
      state.activities = null,
      state.activity = null,
      state.activity_id = null
    },
    add_member(state, {member_id}){
      state.activity.members[member_id] = {name:'', id:member_id}
      state.activity.member_count = state.activity.members.length
    },
  },
  actions: {
    get_activities:async(context, {refresh=true}={})=>{
      let user_id = context.state.users.profile.id
      if ( context.state.activities !== null && refresh === false ) {
        return;
      }
      console.log("getting_activites")
      const db = getFirestore()

      const q = query( collection( db, "activities" ), where("members."+user_id, "!=", false))
      onSnapshot(q, (querySnapshot)=>{
        querySnapshot.forEach((doc) => {
          context.commit("add_activity", {id:doc.id, activity:doc.data()})
        });
      })
      if ( context.state.activities === null ){
        context.commit("set_activities", [])
      }
      return true
    },
    get_activity:async(context, {activity_id})=>{
      if ( context.state.activities === null ){
        context.state.activities = {}
      }
      let existing_activity = context.state.activities.find(a=>a.id===activity_id)
      if ( existing_activity ){
        context.commit("set_activity", existing_activity)
      } else {
        console.log("getting_activity")
        const db = getFirestore()
        const docRef = doc(db, "activities", activity_id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          let activity = docSnap.data()
          context.commit('set_activity', activity)
          context.commit("add_activity", {id:activity_id, activity})

        } else {
          console.log("No such document!");
        }

      }
    },
    get_entries:async(context,{activity_id})=>{
      let existing_activity = context.state.activities.find(a=>a.id===activity_id)
      let last_sync = existing_activity.last_sync || 0;
      
      let load_from = new Date().getTime() - 10 * 86400000
      console.log("getting entries")
      const db = getFirestore()
      const entries_ref = collection( db, "activities", activity_id, "entries" )
      const q = query( entries_ref, where( 'last_modified', '>', last_sync) )
      context.commit('save_last_sync', {activity_id, last_sync:load_from})

      const changes = onSnapshot(q, (querySnapshot)=>{
          let entries = []
          querySnapshot.forEach((doc) => {
              entries.push({id:doc.id, ...doc.data()})
          });
          context.commit('add_entries', entries )
      })
    },
    add_entry:async(context, {activity_id, entry})=>{
      const db = getFirestore()
      const entries_ref = collection( db, "activities", activity_id, "entries" )
        await addDoc(entries_ref,entry)
    },
    delete_entry:async(context, {activity_id, entry_id})=>{
      const db = getFirestore();
      const docRef = doc(db, "activities", activity_id, "entries", entry_id);
      return deleteDoc(docRef).then(()=>{
        context.commit("remove_entry", entry_id)
      })
    },
    update_entry:async(context, {activity_id, entry})=>{
      const db = getFirestore();
      const docRef = doc(db, "activities", activity_id, "entries", entry.id);
      return updateDoc(docRef, entry).then((a)=>{
        context.commit("update_entry", entry)
      })
    },
    create_activity:async(context, activity)=>{
      const db = getFirestore()
      const ref = collection( db, "activities" )
      return await addDoc(ref,activity)
    },
    add_member:async(context, {activity_id, member_id})=>{
      const db = getFirestore()
      let ref = doc(db, "activities", activity_id)
      let key = `members.${member_id}`
      return updateDoc(ref, {[key]:{name:''}}).then(
        context.commit('add_member', {member_id})
      )
    }
  },
  modules: {
      users
  },
  plugins: [
    vuexLocal.plugin
  ]
})
