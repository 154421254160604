import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import { getAuth, onAuthStateChanged } from "firebase/auth";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/settings',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk (settings.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/activities',
    name: 'Activities',
    component: () => import(/* webpackChunkName: "activities" */ '../views/Activities.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/activity/:id',
    name: 'Activity',
    component: () => import(/* webpackChunkName: "activty" */ '../views/Activity.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})



router.beforeEach((to, from, next)=>{
  if ( to.matched.some(record => record.meta.requiresAuth)){
    if ( store.state.users.logged_in){
      next()
    } else {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if ( !user || !user.uid ){
          next({
            path: '/login',
            params: { nextUrl: to.fullPath }
          })
        } else {
          next()
        }
  
      })
    }
  } else {
    next()
  }
})

export default router
