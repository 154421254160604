<template>
  <div class="home">
    <h1>Together Challenge
      <img alt="Vue logo" style="height:50px; vertical-align:bottom" src="../assets/logo/logo_black.svg">
    </h1>
  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {},

  created(){
    if ( this.$store.state.users.logged_in ){
      this.$router.push('/activities')
    }
  }
}
</script>
