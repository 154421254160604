import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initializeApp, getApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence,connectFirestoreEmulator } from "firebase/firestore"; 
import BalmUI from 'balm-ui'; // Official Google Material Components
import BalmUIPlus from 'balm-ui-plus'; // BalmJS Team Material Components
import 'balm-ui-css';
import './registerServiceWorker'
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


const firebaseConfig = {
    apiKey: "AIzaSyAehjuJ3HFrLBGevzVsbfNTqoLAW4Wh2pI",
    authDomain: "together-challenge-167516.firebaseapp.com",
    databaseURL: "https://together-challenge-167516.firebaseio.com",
    projectId: "together-challenge-167516",
    storageBucket: "together-challenge-167516.appspot.com",
    messagingSenderId: "977377265481",
    appId: "1:977377265481:web:08f3d41bc99a4905313c98"
};

// Initialize Firebase
let fa = initializeApp(firebaseConfig)
// if (process.env.NODE_ENV === 'development') {
//   const functions = getFunctions(getApp());
//   connectFunctionsEmulator(functions, "localhost", 5001);
//   connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
// } 

import { getMessaging, getToken, onMessage } from "firebase/messaging";
const messaging = getMessaging();

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});

const auth = getAuth();
// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     // User is signed in, see docs for a list of available properties
//     // https://firebase.google.com/docs/reference/js/firebase.User
//     const uid = user.uid;
//     if ( uid ){
//         // store.commit('users/set_logged_in', true)
//         // store.commit('users/set_user', user)
//     }
//     // ...
//   } else {
//     // User is signed out
//     // ...
//   }
// });

const app = createApp(App)
.use(store)
.use(router)
.use(BalmUI)
.use(BalmUIPlus)

// app.config.globalProperties.$messaging = firebaseMessaging

app.mount('#app')